import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_SIP_SUBSCRIBER_SERVICE}/sip-subscriber-service`);

const sipSubscriberService = {
    getSipSubscribersTable(params, then, error) {
        service.getRequest(1, 'sip-subscribers/table', params, then, error);
    },
    getSipSubscriber(id, version, then, error) {
        service.getRequest(1, 'sip-subscriber/{id}', {version}, then, error, id);
    },
    createSipSubscriber(subscriber, then, error) {
        service.putRequest(1, 'sip-subscriber', subscriber, then, error);
    },
    updateSipSubscriber(subscriber, then, error) {
        service.patchRequest(1, 'sip-subscriber/{id}', subscriber, then, error, subscriber.id);
    },
    deleteSipSubscriber(id, then, error) {
        service.deleteRequest(1, 'sip-subscriber/{id}', null, then, error, id);
    },
};

export default sipSubscriberService;